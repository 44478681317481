/* eslint-disable object-shorthand */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable quotes */
import React, { useEffect, useState } from 'react';

import { Container, Col, Row, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import './funcionarios.css';
import { Link } from 'react-router-dom';
import { FiEdit, FiSearch, FiRefreshCw } from 'react-icons/fi';
import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';

// COMPONENTS
import MUIDataTable from 'mui-datatables';
import FooterLinks from '../../../Account/Footer';

// Breadcrumbs
import BreadFuncionarios from '../../../App/Breadcrumbs/Administracao/Funcionarios/BreadFuncionarios';

import TodosFuncionarios from '../../../../redux/actions/FuncionariosActions/TodosOsFucionariosAction';
import FuncionarioCategorias from '../../../../redux/actions/FuncionariosActions/categoriasActions';
import SyncFeriasAllFuncionarios from '../../../../redux/actions/FuncionariosActions/syncFerias/syncFeriasAllFuncionariosAction';

const useStyles = makeStyles({
  btnSync: {
    color: '#E21450',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  btnSyncLoading: {
    height: '40px',
    fontWeight: 'bold',
    display: 'flex',
    border: `1px solid #707070`,
    backgroundColor: grey[300],
  },
});

const ListaFuncionarios = () => {
  const classes = useStyles();
  const [responsive] = useState('standard');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const funcionarios = useSelector((state) => state.funcionarios.todosFuncionarios);

  // console.log(funcionarios);

  const fetchFuncionarios = () => {
    dispatch(TodosFuncionarios());
  };

  const fetchCategorias = () => {
    dispatch(FuncionarioCategorias());
  };

  useEffect(() => fetchFuncionarios(), []);
  useEffect(() => fetchCategorias(), []);

  const syncFerias = () => {
    setLoading(true);
    dispatch(SyncFeriasAllFuncionarios(setLoading));
  };

  const columns = [
    {
      name: 'id',
      label: 'ID',
    },
    {
      name: 'nome2',
      label: 'Funcionário',
    },
    {
      name: 'ativo',
      label: '',
      options: {
        filter: true,
        sort: false,
        empty: true,
        display: false,
      },
    },
    {
      name: 'operacional',
      label: '',
      options: {
        filter: true,
        sort: false,
        empty: true,
        display: false,
      },
    },
    {
      name: 'estado',
      label: 'Estado',
      options: {
        empty: true,
        filter: true,
        // customHeadLabelRender: () => ({
        //   fontWeight: 'bold',
        // }),
        setCellProps: () => ({
          align: 'left',
        }),
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMeta) => {
          const ativo = tableMeta.rowData[2];
          const operacional = tableMeta.rowData[3];
          return (
            <>
              {ativo && operacional && <p> Operacional </p>}
              {ativo && !operacional && <p> Escritório </p>}
              {!ativo && !operacional && <p> Não Ativo </p>}
            </>
          );
        },
      },
    },

    {
      name: 'id',
      label: ' ',
      options: {
        filter: true,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'right',
        }),
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-funcionarios">
              <Link to={`/app/administracao/funcionarios/${value}`}>
                <Button>
                  <FiEdit size={30} strokeWidth={1} color="#000" />
                </Button>
              </Link>
            </div>
          );
        },
      },
    },
  ];

  const tablePage = useSelector((state) => state.global.funcionariosTablePage);
  const searchTextValue = useSelector((state) => state.global.funcionariosTableSearch);
  const [searchText, setSearchText] = useState(searchTextValue);

  const options = {
    filter: false,
    filterType: 'dropdown',
    print: false,
    search: false,
    searchText: searchText,
    download: false,
    selectableRows: 'none',
    viewColumns: false,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    elevation: 0,
    rowsPerPage: 100,
    rowsPerPageOptions: [100, 150, 200, 250, 300],
    page: tablePage,
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        dispatch({ type: 'FUNCIONARIOS_TABLE_PAGINATION', payload: tableState.page });
      }
      if (action === 'propsUpdate') {
        dispatch({
          type: 'FUNCIONARIOS_TABLE_SEARCH',
          payload: tableState.searchText,
        });
      }
    },
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: 'Pesquisar',
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
    },
  };

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <BreadFuncionarios />
            <h4
              style={{
                fontSize: '22px',
                width: '100%',
                marginTop: 20,
                marginBottom: 20,
              }}
              className="text-underline"
            >
              Funcionários
            </h4>
          </Col>
        </Row>

        <CardBody>
          <Col md={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ mt: 2.5 }}>
                <Button
                  className={loading ? classes.btnSyncLoading : classes.btnSync}
                  disabled={!!loading}
                  variant="contained"
                  onClick={() => syncFerias().then(() => setLoading(false))}
                  startIcon={loading ? null : <FiRefreshCw />}
                >
                  {loading && (
                    <CircularProgress size={14} style={{ color: '#9d9d9d', marginRight: '10px' }} />
                  )}
                  Sincronizar férias
                </Button>
              </Box>
              <Box sx={{ mb: 2 }}>
                <b>Pesquisar</b>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="search"
                  type="text"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <FiSearch />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
              <h4
                style={{
                  width: '100%',
                }}
                className="text-underline-title"
              >
                Lista de Funcionários
              </h4>
            </Box>
          </Col>
          <Col md={12}>
            <MUIDataTable
              description="Nada para mostrar"
              data={funcionarios}
              columns={columns}
              options={options}
            />
          </Col>
        </CardBody>
      </Container>
      <div className="bottom">
        <div style={{ padding: '20px 40px' }}>
          <FooterLinks />
        </div>
      </div>
    </>
  );
};

export default ListaFuncionarios;
